
import { environment } from './../../environments/environment.prod';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {UserService} from './user.service';

@Injectable({
    providedIn: 'root',
})
export class ListService {
    noAuthHeader = {headers: new HttpHeaders({'NoAuth': 'True'})};
    constructor(private http: HttpClient, private userServ: UserService) {}

    getAllUserList() {
        return this.http.get(environment.apiBaseUrl + '/sadmin/user/list-all');
    }

    getAllAdminList() {
        return this.http.get(environment.apiBaseUrl + '/sadmin/admin/list-all');
    }

    getUserList() {
        const adminId = this.userServ.getUserPayload()._id;
        const query = {'adminId': adminId};
        return this.http.get(environment.apiBaseUrl + '/user/list-all', {params: query} );
    }
}