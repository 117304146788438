import { MatDialogModule } from '@angular/material/dialog';
import { ListService } from './shared/list.service';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {NbMenuModule,NbSidebarModule,} from '@nebular/theme';

import { AuthInterceptor } from './auth/auth.interceptors';
import { UserService } from './shared/user.service';
import { AuthGuard } from './auth/auth.guard';
import {DialogComponent} from './shared/dialog.component';
import { CommonModule } from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import { MatCard, MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { AgmCoreModule } from '@agm/core';
import { ProfileBarChartComponent } from './profile/profile-bar-chart/profile-bar-chart.component';


@NgModule({
  declarations: [AppComponent, DialogComponent, ProfileBarChartComponent],
  providers:[[{
    provide: HTTP_INTERCEPTORS,
    useClass : AuthInterceptor,
    multi: true,
  }, AuthGuard, UserService, ListService]],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    // AgmCoreModule.forRoot({apiKey: 'AIzaSyClgPVSmWeD0kFhJxO8ni82vj74VIcyoAg', libraries: ['places', 'geometry', 'drawing']}),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
