import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CommonModule } from '@angular/common';


export interface DialogData {
    type: null;
    message: null;
}

@Component({
    selector: 'ngx-dialog',
    templateUrl: './dialog.component.html',
})
export class DialogComponent {
    constructor(
        public dialogRef: MatDialogRef<DialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

    exit() {
        this.dialogRef.close();
    }
}