import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';
import { UserService } from '../shared/user.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private http: HttpClient,
    private _userService: UserService) {}

  getNotification() {
    let body = {
      userId: this._userService.getUserId()
    };
    return this.http.post(environment.apiBaseUrl + '/notification/get-unread-notification', body);
  }

  readNotificationSetTrue(notificationIds: string []) {
    let body = {
      notificationIds: notificationIds
    }

    return this.http.post(environment.apiBaseUrl + '/notification/change-read-status-true', body);
  }
}
