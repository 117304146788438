import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UserService } from '../../../shared/user.service';

import { Router } from "@angular/router";
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user = {
    name: 'Guest',
    picture: null,
  };

  currentTheme = 'corporate';

  userMenu = [ { title: 'Profile' }, { title: 'Log out' } ];

  // Notification
  unreadNotifications = [];
  numberOfUnreadNotification = '';

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private userServ: UserService,
              private router: Router,
              private _notificationService: NotificationService) {
  }

  ngOnInit() {

    this.user.name = this.userServ.getName() || 'Guest';

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

      this.changeTheme('default');

      this.menuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'UserMenu'),
        map(({ item: { title } }) => title),
      ).subscribe(t => {
        if(t === 'Log out'){
          this.userServ.deleteToken();
          this.router.navigateByUrl('/login');
        } else if(t === 'Profile'){
          this.router.navigateByUrl('/home/profile');
        }
      })

      this.getNotification();
  }

  getNotification() {
    this._notificationService.getNotification().subscribe((res:any) => {
      this.unreadNotifications = res.data;
      this.numberOfUnreadNotification = res.data.length > 0? res.data.length : '';
    })
  }

  notificationRead() {
    let notificationIdList = [];
    this.unreadNotifications.forEach(notification => {
      notificationIdList.push(notification._id);
    });
    this._notificationService.readNotificationSetTrue(this.unreadNotifications).subscribe((res:any) => {
      // console.log(res)
    }) 
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.sidebarService.toggle(false, 'menu-sidebar-project');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
