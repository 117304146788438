import { Injectable } from '@angular/core';
import { CanActivate , ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from "../shared/user.service";


@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  constructor(private router : Router, private userService: UserService){}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      if (!this.userService.isLoggedIn()) {
        this.router.navigateByUrl('/login');
        this.userService.deleteToken();
        return false;
      }
    return true;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {

  constructor(private router : Router, private userService: UserService){}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!(this.userService.getRole() == 'admin' || 'sadmin')) {
      this.router.navigateByUrl('/home');
      return false;
    }
  return true;
}
}


@Injectable({
  providedIn: 'root',
})
export class SuperAdminGuard implements CanActivate {

  constructor(private router : Router, private userService: UserService){}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!(this.userService.getRole() == 'sadmin')) {
      this.router.navigateByUrl('/home');
      return false;
    }
  return true;
}
}
