import { environment } from './../../environments/environment.prod';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root',
})
export class UserService {
  noAuthHeader = {headers: new HttpHeaders({'NoAuth': 'True'})};
  constructor(private http: HttpClient) {}

  // Register Users
  registerUser(user) {return this.http.post(environment.apiBaseUrl + '/register', user); }
  registerAdmin(admin){return this.http.post(environment.apiBaseUrl + '/admin/register', admin); }
  registerSAdmin(sadmin){return this.http.post(environment.apiBaseUrl + '/superAdmin/register', sadmin, 
      this.noAuthHeader); }

  // Login Users
  login(credentials) {return this.http.post(environment.apiBaseUrl + '/login', credentials, this.noAuthHeader); }
  adminLogin(credentials) {return this.http.post(environment.apiBaseUrl
      + '/admin/login', credentials, this.noAuthHeader); }
  sadminLogin(credentials) {return this.http.post(environment.apiBaseUrl
    + '/superAdmin/login', credentials, this.noAuthHeader); }



  // Generic Set LocalStorage
  setToken(token) {localStorage.setItem('token', token); }
  setName(name) {localStorage.setItem('name', name); }
  setEmail(email) {localStorage.setItem('email', email); }
  setRole(role) {localStorage.setItem('role', role); }
  setCompanyId(companyId) {localStorage.setItem('companyId',companyId)}

  // Get LocalStorage
  getToken() {return localStorage.getItem('token'); }
  getName() {return localStorage.getItem('name'); }
  getEmail() {return localStorage.getItem('email'); }
  getRole() {return localStorage.getItem('role'); }
  getCompanyId() {return localStorage.getItem('companyId')}

  // Delete LocalStorage Data
  deleteToken() {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('name');
    localStorage.removeItem('role');
  }

  // Get User Payloads
  getUserPayload() {
    const token = this.getToken();
    if (token) {
      const userPayload = atob(token.split('.')[1]);
      return JSON.parse(userPayload);
    } else {
      return null;
    }
  }

  // Get User Id
  getUserId() {
    const token = this.getToken();
    if (token) {
      const userPayload = atob(token.split('.')[1]);
      return JSON.parse(userPayload)._id;
    } else {
      return null;
    }
  }

  // Check Logged in
  isLoggedIn() {
    const userPayload = this.getUserPayload();
    if (userPayload)
      return userPayload.exp > Date.now() / 1000;
    else
      return false;
  }

  getUserDetails() {
    return this.http.post(environment.apiBaseUrl + '/getUserDetails', {userId: this.getUserId()});
}

}
